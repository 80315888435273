import { types } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { colors, constants } from "@/utils/global"
import parse from "html-react-parser"
import {
  memo,
  useId, useMemo, useRef, useState
} from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"
import { MdImportExport, MdNewReleases } from "react-icons/md"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"

export const OrderFilterSearch = memo(({ ...props }) => {
  const {
    texts, filters, sticky, left, motovo, setOrder, hostname, orderFilter, setOrderFilter
  } = props
  const { mobile } = hostname
  const cataloguePage = useMemo(() => texts?.catalogue_page, [texts])
  const anchorRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const iconStyle = { fontSize: 12, marginRight: 4 }

  const options = useMemo(() => {
    const { order, ...opt } = types.catalog.order
    const { desc, asc, new: newF } = types.catalog.order
    const tmpOpt = Object.entries(opt).map(x => {
      const tmpElem = {
        [types.catalog.order.desc]: <FaArrowUp style={iconStyle} />,
        [types.catalog.order.asc]: <FaArrowDown style={iconStyle} />,
        [types.catalog.order.new]: <MdNewReleases style={iconStyle} />,
      }
      const element = tmpElem[x[1]]
      return {
        ...constants.OBJ_FILTER,
        type: order,
        parent: types.catalog.get,
        childId: x[1],
        pathParams: [ `GET`, `o`, x[1] ],
        element,
      }
    })
    return tmpOpt
  }, [iconStyle])

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleChangePath = async item => {
    if (filters.find(x => x.childId === item.childId)) {
      await setOrder()
      return false
    }
    await setOrder(item)
  }

  const MenuItems = () => (
    <>
      {options.map((item, i) => (
        <div
          key={handleKeyRandom(idRef, i)}
          aria-label="static-item"
          onClick={() => handleChangePath(item)}
          disabled={motovo && item.childId === types.catalog.order.new}
          className={filters.find(x => x.childId === item.childId) ? `active` : `no-active`}
          style={{
            fontSize: mobile ? `13px` : `12px`,
            padding: `6px`,
            alignItems: `center`,
            display: `flex`,
            right: 10,
          }}
        >
          <input type="checkbox" style={{ color: colors.secondary }} checked={!!filters.find(x => x.childId === item.childId)} />
          &nbsp;
          <span className="ms-1">{parse(cataloguePage?.order_list[i] ?? ``)}</span>
        </div>
      ))}
    </>
  )

  const ListMenu = () => (
    <div
      className="p-2 items-center"
      style={{
        background: `white`,
        boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.1)`,
        borderRadius: `4px`,
        zIndex: 1501,
        position: `absolute`,
      }}
    >
      <div id={idRef}
        style={{
          padding: 0,
          maxHeight: `195px`,
          overflowY: `auto`,
        }}>
        <MenuItems />
      </div>
    </div>
  )

  return !!cataloguePage && (
    <div
      style={{
        margin: !mobile && `0px 4px 4px 0px`,
        width: !mobile ? `auto` : `150px`,
        position: `relative`
      }}
    >
      <button
        id="order-filter"
        ref={anchorRef}
        className={`bg-gray-200 rounded-full p-1 ${mobile && sticky ? `is-pinned` : ``}`}
        aria-controls={orderFilter ? `split-filter-order` : undefined}
        aria-expanded={orderFilter ? `true` : undefined}
        aria-label="order-filters"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setOrderFilter(!orderFilter)
        }}
        style={{ minWidth: 65, zIndex: 1501 }}
      >
        <div
          style={{
            width: `100%`,
            display: `flex`,
            justifyContent: mobile ? `center` : `space-between`,
            alignItems: `center`,
          }}
        >
          {filters[0] ? filters[0].element : <MdImportExport style={iconStyle} />}
          <p>
            {cataloguePage.order_by}
          </p>
          <DropDownArrows {...{ orderFilter }} />
        </div>
      </button>
      {orderFilter && (
        <div
          id="aa"
          className="w-full"
          style={{
            position: `absolute`,
            width: `300px`,
            maxWidth: `300px`,
            top: `100%`,
            left: 0,
            zIndex: 1501,
            marginTop: `4px`,
          }}
        >
          <ListMenu />
        </div>
      )}
    </div>
  )
})
